/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable prettier/prettier */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-statements */
import { useCart as useCartComposable } from '@gemini-vsf/composables';
import { computed } from '@nuxtjs/composition-api';
import { useUiState, useUiNotification, useUser, useGtm, useTranslation } from '~/composables';
import { CartNote, UseCart } from '~/types/types';
import { useI18n } from "~/helpers/hooks/usei18n";
import { sharedRef } from "@vue-storefront/core";
import { addNote, parseNotes, getNote } from './_helpers'


const useCart = (): UseCart => {
  const { $gt } = useTranslation('translations');
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const {
    load: loadCart,
    cart,
    addItem: addItemComposable,
    bulkAddItems: bulkAddItemsComposable,
    addNoteOnCart: addNoteOnCartComposable,
    applyCoupon: applyCouponComposable,
    applyGiftCard: applyGiftCardComposable,
    removeCoupon: removeCouponComposable,
    removeGiftCard: removeGiftCardComposable,
    removeItem: removeItemComposable,
    updateItemQty: updateItemQtyComposable,
    setAdditionalInfo: setAdditionalInfoComposable,
    isInCart,
    error,
  } = useCartComposable();
  const { toggleCartSidebar } = useUiState();
  const { send: sendNotification } = useUiNotification();
  const trans = useI18n();

  const { isAuthenticated } = useUser();
  const { addToCartPush } = useGtm();

  const cartTotalItems = computed(() => (cart?.value && cart.value?.total_quantity) || null);
  const cartItems = computed(() => (cart?.value && cart.value?.items?.filter(Boolean)) || []);
  const cartNotes = computed(() => (cart?.value?.notes && parseNotes(cart.value.notes)) || {});
  const cartId = computed(() => cart?.value?.id);
  const cartIsVirtual = computed(() =>
    cartItems?.value?.every((item) => item?.product?.is_virtual));
  const cartHasGiftcard = computed(() =>
    cartItems?.value?.some((item) => item?.product?.is_giftcard));
  const giftcardAmountApplied = computed(() =>
    cart?.value?.selected_payment_methods?.
    filter((method) => method?.code === 'giftcard').
    map((giftcard) => {
    if (giftcard.payload && typeof giftcard.payload === 'string') {
      giftcard.payload = JSON.parse(giftcard.payload);
    }
      return {
        amount: giftcard.amount.value ?? 0,
        // @ts-ignore
        code: (typeof giftcard.payload === 'object' ? giftcard?.payload?.gift_card_code : '') as string
      };
    }
  )?.[0]);
  const cartHasGiftCardApplied = computed(() =>
    cart?.value?.selected_payment_methods?.
    some((method) => method?.code === 'giftcard'));
  const handleEvent = (action = 'load') => {
    const skipActions = [
      'clear',
      'load',
      'removeItem',
      'bulkAddItems',
      'addNoteOnCart',
      'removeNoteFromCartItem',
      'updateItemQty',
      'setItemQuantity',
      'reorder'
    ];

    const errorMessages = {
      addItem: $gt('Error adding item to cart.'),
      removeItem: $gt('Error removing item from cart.'),
      updateItemQty: $gt('Error updating item quantity.'),
      load: $gt('Error loading cart.'),
      clear: $gt('Error clearing cart.'),
      applyCoupon: $gt('Error applying coupon.'),
      removeCoupon: $gt('Error removing coupon.'),
      applyGiftCard: $gt('Error applying Gift Card.'),
      removeGiftCard: $gt('Error removing Gift Card.'),
    };

    const errorType = Object.keys(error.value).find(key => error.value[key]);

    if (skipActions.includes(action) && (errorType !== action)) return;

    const notificationType = errorType ? 'danger' : 'success';
    const message = errorType
      ? errorMessages[errorType]
      : (action === 'applyGiftCard' && notificationType === 'success')
        ? $gt('Gift card applied successfully.')
        : (action === 'removeGiftCard' && notificationType === 'success')
          ? $gt('Gift card removed successfully.')
          : $gt('Operation successful.');
    const icon = errorType ? 'error' : 'check';

    sendNotification({
      id: Symbol(errorType ? 'cart_error' : 'cart_success'),
      message,
      type: notificationType,
      icon,
      persist: !!errorType,
      title: errorType ? $gt('Error') : $gt('Success'),
    });

    errorMessages[errorType] = null;
  };

  const load = async () => {
    await loadCart({
      customQuery: isAuthenticated.value
        ? {
          customerCart: 'customerCartCustom',
        }
        : {
          cart: 'cartCustom',
        },
    });
    handleEvent('load');
  };

  const addItem = async ({ item, quantity }, openSidebar = true) => {
    try {
      await addItemComposable({
        product: item,
        quantity,
        customQuery: {
          addConfigurableProductsToCart: 'addConfigurableProductsToCartCustom',
        },
      });
      addToCartPush(item, { quantity });
      await load();
      if (cart?.value?.items?.length > 0 && openSidebar) toggleCartSidebar();
    } catch (error) {
      console.error("addItem ~ error:", error);
    }
    handleEvent('addItem');
  };

  const bulkAddItems = async (products: any[], openSidebar = true) => {
    try {
      await bulkAddItemsComposable({
        products,
        customQuery: {
          addProductsToCart: 'addProductsToCartCustom',
        },
      });
      handleEvent('bulkAddItems');
      if (cart?.value?.items?.length > 0 && openSidebar) toggleCartSidebar();
    } catch (error) {
      console.error("bulkAddItems ~ error:", error);
    }
  }

  const addNoteOnCartV2 = async (noteKey: string, note: string) => {
    try {
      const newNote = addNote(noteKey, note, cartNotes.value);
      await addNoteOnCartComposable({
        note: newNote,
        customQuery: { setNotesToCart: 'setNotesToCartCustom' },
      });
      await load();
    } catch (error) {
      console.error("addNoteOnCart ~ error:", error);
    }
    handleEvent('addNoteOnCart');
  };

  const removeNoteFromCartItem = async (merchantSku: string) => {
    try {
      const cartNotesCopy = { ...cartNotes.value };
      delete cartNotesCopy.items?.[merchantSku];
      if (cartNotesCopy.items && Object.keys(cartNotesCopy.items).length === 0) delete cartNotesCopy.items;
      await addNoteOnCartComposable({
        note: JSON.stringify(cartNotesCopy),
        customQuery: { setNotesToCart: 'setNotesToCartCustom' },
      });
    } catch (error) {
      console.error("Could not remove cart note", error);
    }
    handleEvent('removeNoteFromCartItem');
  }

  const applyCoupon = async (couponCode: string) => {
    await applyCouponComposable({
      couponCode,
      customQuery: {
        applyCouponToCart: 'applyCouponToCartCustom',
      },
    })
  };

  const applyGiftCard = async (giftCardCode: string) => {
      await applyGiftCardComposable({
        giftCardCode,
        customQuery: {
          applyGiftCardToCart: 'applyGiftCardToCartCustom',
        },
      });
      handleEvent('applyGiftCard');
  };

  const removeCoupon = async (couponCode: string) => {
    await removeCouponComposable({
      couponCode,
      customQuery: {
        removeCouponFromCart: 'removeCouponFromCartCustom',
      },
    });
  };

  const removeGiftCard = async (giftCardCode: string) => {
      await removeGiftCardComposable({
        giftCardCode,
        customQuery: {removeGiftCardFromCart: 'removeGiftCardFromCartCustom'},
      });
      handleEvent('removeGiftCard');
  };

  const removeItem = async ({ product }) => {
    await removeItemComposable({
      product,
      customQuery: {
        removeItemFromCart: 'removeItemFromCartCustom',
      },
    });
    await removeNoteFromCartItem(product?.product?.merchant_sku);
    handleEvent('removeItem');
  };

  const updateItemQty = async ({ product }, quantity: number) => {
    try {
      await updateItemQtyComposable({
        product,
        quantity,
        customQuery: {
          updateCartItems: 'updateCartItemsCustom',
        },
      });
    } catch (error) {
      console.error("updateItemQty ~ error:", error);
    }
    handleEvent('updateItemQty');
  };

  const setItemQuantity = async ({ product, quantity }) => {
    try {
      await updateItemQtyComposable({
        product,
        quantity,
        customQuery: {
          updateCartItems: 'updateCartItemsCustom',
        },
      });
    } catch (error) {
      console.error("setItemQuantity ~ error:", error);
    }
    handleEvent('setItemQuantity');
  };
  const setAdditionalNoteData = async (additionalData: Array<CartNote>) => {
    if (additionalData) {
      let billingNote = '';
      additionalData.forEach((d) => {
        billingNote += `${trans.t(d.noteKey)}: ${d.note} `;
      });
      await addNoteOnCartComposable({
        note: billingNote,
      });
    }
  }

  const setAdditionalInfo = async ({ productId, message, email, customQuery }) => {
    if (productId && email) {
      await setAdditionalInfoComposable({ productId, message, email, customQuery });
      await load();
    }
  }

  return {
    ...useCartComposable(),
    load,
    cart,
    addItem,
    bulkAddItems,
    addNoteOnCartV2,
    setAdditionalNoteData,
    applyCoupon,
    applyGiftCard,
    removeCoupon,
    removeGiftCard,
    removeItem,
    updateItemQty,
    isInCart,
    cartTotalItems,
    cartItems,
    error,
    setItemQuantity,
    cartNotes,
    getNote,
    isAuthenticated,
    cartId,
    setAdditionalInfo,
    cartIsVirtual,
    cartHasGiftcard,
    cartHasGiftCardApplied,
    giftcardAmountApplied,
  };
};

export default useCart;
