/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { sharedRef } from '@vue-storefront/core';
import { CheckoutStateReferences as State, CheckoutStep } from '~/types/enums';
import { useRoute, useRouter, computed, useContext } from '@nuxtjs/composition-api';
import { useShipping, useAddresses, useShippingProvider, useBilling, useMakeOrder, usePaymentProvider } from '@gemini-vsf/composables';
import { useCart, useTranslation, useUiNotification, useUser } from '~/composables';
import { useI18n } from '~/helpers/hooks/usei18n';
import dataManipulation from '~/helpers/dataManipulation';
import { checkoutErrorsMap } from '~/helpers/maps';

const STEPS = {
  [CheckoutStep.UserAccount]: 'User Account',
  [CheckoutStep.Shipping]: 'Shipping',
  [CheckoutStep.Billing]: 'Billing',
  [CheckoutStep.Payment]: 'Payment',
};
const { isValidItalianFiscalCode } = dataManipulation();

export const checkoutState = (cartId: string) => {
  const { cart, load: loadCart, setCart, setAdditionalNoteData, cartIsVirtual } = useCart();
  const { user, load: loadUser, isAuthenticated } = useUser();
  const { save: saveShippingAddress, error: shippingErrors, shipping: shippingData } = useShipping();
  const { save: setBillingAddressOnCartComposable, error: billingErrors } = useBilling();
  const { save: createCustomerAddress, error: addressErrors } = useAddresses();
  const { send: sendNotification } = useUiNotification();
  const { save: setShippingMethodOnCartComposable, error: shippingMethodErrors } = useShippingProvider();
  const { load: loadPaymentMethods } = usePaymentProvider();
  const { make: placeOrderComposable, order, loading: orderLoading, error: orderErrors } = useMakeOrder();
  const router = useRouter();
  const route = useRoute();
  const { $gt } = useTranslation('translations');
  const { locale } = useI18n();
  const {
    app,
    app: {
      $vsf: {
        $gemini: {
          config: { state: geminiIntegrationState },
        },
      },
    },
    redirect,
  } = useContext();
  const checkoutLoading = sharedRef(true, `${State.Loading}${cartId}`);
  const stepLoading = sharedRef(false, `${State.StepLoading}${cartId}`);
  const checkoutUser = sharedRef(null, `${State.User}${cartId}`);
  const checkoutShipping = sharedRef(null, `${State.Shipping}${cartId}`);
  const checkoutShippingAddressId = sharedRef(null, `${State.ShippingAddressId}${cartId}`);
  const checkoutShippingMethod = sharedRef(null, `${State.ShippingMethod}${cartId}`);
  const checkoutBilling = sharedRef(null, `${State.Billing}${cartId}`);
  const checkoutBillingAddressId = sharedRef(null, `${State.BillingAddressId}${cartId}`);
  const checkoutBillingCountry = sharedRef(null, `${State.BillingCountry}${cartId}`);
  const checkoutPlaceOfBirth = sharedRef(null, `${State.PlaceOfBirth}${cartId}`);
  const checkoutDateOfBirth = sharedRef(null, `${State.DateOfBirth}${cartId}`);
  const checkoutFiscalCode = sharedRef(null, `${State.FiscalCode}${cartId}`);
  const billingAdditionalDataValidation = sharedRef(false, `${State.billingAdditionalDataValidation}${cartId}`);
  const checkoutPayment = sharedRef(null, `${State.Payment}${cartId}`);
  const checkoutAvailableShippingMethods = sharedRef(null, `${State.AvailableShippingMethods}${cartId}`);
  const checkoutPlacingOrder = sharedRef(false, `${State.PlacingOrder}${cartId}`);
  const currentStep = computed(() => route?.value?.path?.split('/')?.pop());
  const currentStepIndex = computed(() => Object.keys(STEPS).indexOf(currentStep.value));
  const cartCanBeCheckedOut = computed(() =>
    !cartIsVirtual.value
      ? cart?.value?.items?.length > 0 && cart.value.billing_address && cart.value.shipping_addresses?.length > 0
      : cart?.value?.items?.length > 0 && cart.value.billing_address
  );
  const isThankYou = computed(() => currentStep.value === 'payment' && !cart.value && !!order.value);

  const checkoutAdditionalDataIsValid = computed(() => {
    if (!billingAdditionalDataValidation.value) return false;
    const countryId = checkoutBillingCountry.value?.id || checkoutBilling?.value?.country_code;
    if (countryId !== 'IT') {
      return Boolean(countryId) && Boolean(checkoutPlaceOfBirth.value) && Boolean(checkoutDateOfBirth.value);
    }
    return isValidItalianFiscalCode(checkoutFiscalCode.value) && Boolean(checkoutPlaceOfBirth.value) && Boolean(checkoutDateOfBirth.value);
  });

  const checkoutAdditionalDataIsValidForBillingSameAsShipping = computed(() => {
    if (!billingAdditionalDataValidation.value) return false;
    const countryCode = checkoutShipping.value?.country_code;
    if (countryCode !== 'IT') {
      return Boolean(countryCode) && Boolean(checkoutPlaceOfBirth.value) && Boolean(checkoutDateOfBirth.value);
    }
    return isValidItalianFiscalCode(checkoutFiscalCode.value) && Boolean(checkoutPlaceOfBirth.value) && Boolean(checkoutDateOfBirth.value);
  });

  const checkoutAdditionalData = computed(() => ({
    placeOfBirth: checkoutPlaceOfBirth?.value,
    dateOfBirth: checkoutDateOfBirth?.value,
    fiscalCode: checkoutFiscalCode?.value,
  }));

  const isLoggedUser = computed(() => isAuthenticated.value);

  /**
   * Returns the index of the given step in the STEPS object.
   * @param step - The step to get the index of.
   * @returns The index of the given step in the STEPS object.
   */
  const getCurrentStepIndex = (step: string) => Object.keys(STEPS).indexOf(step);

  /**
   * Shows a notification with the given error message for the given step.
   * @param step - The step where the error occurred.
   * @param key - The key of the error message in the checkoutErrorsMap.
   * @param errorMessage
   */
  const notifyCheckoutError = (step: string, key: string, errorMessage?: string) => {
    try {
      const { title, message } = checkoutErrorsMap[step][key];
      sendNotification({
        id: Symbol('checkout_error'),
        message: !errorMessage ? $gt(message) : $gt(errorMessage),
        type: 'danger',
        icon: 'check',
        persist: false,
        title,
      });
    } catch (error) {
      console.error(error);
      return;
    }
    sendNotification({
      id: Symbol('checkout_error_generic'),
      message: $gt('Something went wrong.'),
      type: 'danger',
      icon: 'check',
      persist: false,
      title: $gt('Error'),
    });
  };

  return {
    cart,
    loadCart,
    setCart,
    user,
    loadUser,
    isAuthenticated,
    saveShippingAddress,
    shippingErrors,
    shippingData,
    setBillingAddressOnCartComposable,
    billingErrors,
    createCustomerAddress,
    addressErrors,
    sendNotification,
    setShippingMethodOnCartComposable,
    shippingMethodErrors,
    loadPaymentMethods,
    placeOrderComposable,
    order,
    orderLoading,
    orderErrors,
    router,
    route,
    $gt,
    locale,
    app,
    redirect,
    checkoutLoading,
    stepLoading,
    checkoutUser,
    checkoutShipping,
    checkoutShippingAddressId,
    checkoutShippingMethod,
    checkoutBilling,
    checkoutBillingAddressId,
    checkoutBillingCountry,
    checkoutPlaceOfBirth,
    checkoutDateOfBirth,
    checkoutFiscalCode,
    billingAdditionalDataValidation,
    checkoutPayment,
    checkoutAvailableShippingMethods,
    checkoutPlacingOrder,
    currentStep,
    currentStepIndex,
    cartCanBeCheckedOut,
    isThankYou,
    checkoutAdditionalDataIsValid,
    checkoutAdditionalDataIsValidForBillingSameAsShipping,
    checkoutAdditionalData,
    isLoggedUser,
    geminiIntegrationState,
    STEPS,
    cartIsVirtual,
    getCurrentStepIndex,
    notifyCheckoutError,
    setAdditionalNoteData,
  };
};
